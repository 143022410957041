import React, { useEffect, useState } from 'react';
import { Wrapper }                    from '../components/wrapper/wrapper.component';

export type EmailSignaturePropsType = {
    phone: string;
    email: string;
    address: {label: string; address: string}[];
    links: {label: string; link: string}[];
};

export const EmailSignatureComponent = (props: EmailSignaturePropsType) => {
    const [ name, setName ] = useState('Name Surname');
    const [ job, setJob ] = useState('Job title');
    const [ phone, setPhone ] = useState('+38 000 000-00-00');
    const [ email, setEmail ] = useState('names@psi.kyiv.ua');

    useEffect(() => {
        if (localStorage.getItem('name')) {
            setName(localStorage.getItem('name'));
        }
        if (localStorage.getItem('job')) {
            setJob(localStorage.getItem('job'));
        }
        if (localStorage.getItem('phone')) {
            setPhone(localStorage.getItem('phone'));
        }
        if (localStorage.getItem('email')) {
            setEmail(localStorage.getItem('email'));
        }
    }, []);

    const tdStyle = {
        verticalAlign: 'middle',
        padding:       '0.72pt 0.72pt 0.72pt 0.72pt',
        overflow:      'hidden',
        borderTop:     0,
        paddingRight:  8
    };
    const aStyle: React.CSSProperties = { textDecoration: 'none' };
    const fontStyleBold: React.CSSProperties = {
        fontSize:      '10pt',
        fontFamily:    'Proxima Nova, sans-serif',
        fontWeight:    700,
        verticalAlign: 'baseline',
        whiteSpace:    'pre-wrap'
    };
    const fontStyleReg: React.CSSProperties = {
        fontSize:      '10pt',
        fontFamily:    'Proxima Nova, sans-serif',
        fontWeight:    400,
        verticalAlign: 'baseline',
        whiteSpace:    'pre-wrap'
    };
    const fontStyleItalic: React.CSSProperties = {
        fontSize:      '10pt',
        fontFamily:    'Lora,serif',
        fontStyle:     'italic',
        fontWeight:    400,
        verticalAlign: 'baseline',
        whiteSpace:    'pre-wrap'
    };

    return (
        <Wrapper row padding="xxl">
            { ' ' }
            <Wrapper style={ { userSelect: 'none' } }>
                { ' ' }
                <input
                    value={ name }
                    style={ { margin: 8, padding: 8 } }
                    onChange={ (e) => {
                        if (e && e.target) {
                            setName(e.target.value);
                            localStorage.setItem('name', e.target.value);
                        }
                    } }
                />
                <input
                    value={ job }
                    style={ { margin: 8, padding: 8 } }
                    onChange={ (e) => {
                        if (e && e.target) {
                            setJob(e.target.value);
                            localStorage.setItem('job', e.target.value);
                        }
                    } }
                />
                <input
                    value={ phone }
                    style={ { margin: 8, padding: 8 } }
                    onChange={ (e) => {
                        if (e && e.target) {
                            setPhone(e.target.value);
                            localStorage.setItem('phone', e.target.value);
                        }
                    } }
                />
                <input
                    value={ email }
                    style={ { margin: 8, padding: 8 } }
                    onChange={ (e) => {
                        if (e && e.target) {
                            setEmail(e.target.value);
                            localStorage.setItem('email', e.target.value);
                        }
                    } }
                />

            </Wrapper>
            { ' ' }
            <Wrapper>
                <div style={ { display: 'block' } }>
                    <div>
                        <table style={ { border: 'none', borderCollapse: 'collapse' } }>
                            <colgroup>
                                <col width="70" />
                                <col width="556" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td style={ tdStyle }>
                                        { /* <img src="https://ucarecdn.com/2ef27348-a8c1-4dda-be61-80c4511bad1b/round.png" alt="" width="55" height="55" /> */ }
                                        { /* <img src="https://static.onepage.io/media/796af09f-9d84-49e4-a405-abad53dc5f4c/md2x" alt="" width="110" height="55" /> */ }
                                        <img src="https://static.onepage.io/media/6eb4abdf-ed48-4527-9f7f-e70c2b9f6e2d/md2x" alt="" width="250" height="55" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                    <p dir="ltr">
                        <span style={ fontStyleBold }>{ `${ name }, ` }</span>
                        { ' ' }
                        <span style={ fontStyleItalic }>{ `${ job }` }</span>
                        <span style={ fontStyleItalic }>&nbsp;</span>
                    </p>
                    <br />
                    <p>
                        <span style={ fontStyleBold }>{ props.phone }</span>
                        { ' ' }
                        <span style={ fontStyleReg }><a style={ aStyle } href={ `tel:${ phone.replace(' ', '') }` } target="_blank" rel="noreferrer">{ `${ phone }, ` }</a></span>
                        <span style={ fontStyleBold }>{ props.email }</span>
                        <span style={ fontStyleReg }><a style={ aStyle } href={ `mailto:${ email.replace(' ', '') }` } target="_blank" rel="noreferrer">{ email }</a></span>
                        <span style={ fontStyleBold }><br /></span>
                        { ' ' }
                        { props.address.map((item) => (
                            <>
                                <span style={ fontStyleBold }>{ item.label }</span>
                                <span style={ fontStyleReg }>{ item.address }</span>
                                <br />
                            </>
                        )) }
                        { props.links.map((item, index) => (
                            <>
                                <span style={ fontStyleReg }><a href={ item.link } style={ { ...aStyle, fontWeight: 'bold' } }>{ item.label }</a></span>
                                { index < (props.links.length - 1) && <span style={ fontStyleReg }>{ ' • ' }</span> }
                            </>
                        )) }
                    </p>
                    <br />
                </div>
            </Wrapper>

        </Wrapper>

    );
};
